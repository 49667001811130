var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "users"
  }, [_c('card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h4', [_vm._v("Alle Benutzer")])];
      },
      proxy: true
    }])
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', {
    staticClass: "noselect"
  }, [_c('th', [_c('a', {
    staticClass: "link sortlink",
    class: _vm.sortClass('firstname'),
    on: {
      "click": function click($event) {
        return _vm.setSortValue('firstname');
      }
    }
  }, [_vm._v("Vorname"), _c('div', {
    staticClass: "icons"
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('i', {
    staticClass: "icon-arrow-top"
  })])])]), _c('th', [_c('a', {
    staticClass: "link sortlink",
    class: _vm.sortClass('lastname'),
    on: {
      "click": function click($event) {
        return _vm.setSortValue('lastname');
      }
    }
  }, [_vm._v("Nachname"), _c('div', {
    staticClass: "icons"
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('i', {
    staticClass: "icon-arrow-top"
  })])])]), _c('th', [_c('a', {
    staticClass: "link sortlink",
    class: _vm.sortClass('email'),
    on: {
      "click": function click($event) {
        return _vm.setSortValue('email');
      }
    }
  }, [_vm._v("Email"), _c('div', {
    staticClass: "icons"
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('i', {
    staticClass: "icon-arrow-top"
  })])])]), _c('th', [_c('a', {
    staticClass: "link sortlink",
    class: _vm.sortClass('userRole'),
    on: {
      "click": function click($event) {
        return _vm.setSortValue('userRole');
      }
    }
  }, [_vm._v("Rolle"), _c('div', {
    staticClass: "icons"
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('i', {
    staticClass: "icon-arrow-top"
  })])])]), _c('th', [_vm._v("FB UID")]), _c('th', [_vm._v("USER ID")]), _c('th', [_vm._v("CONTACT ID")])]), _c('tbody', _vm._l(_vm.sortedUsers, function (user, id) {
    return _c('tr', {
      staticClass: "hoverTr",
      class: {
        _opened: false
      },
      on: {
        "click": function click($event) {
          return _vm.selectUser(user);
        }
      }
    }, [_c('td', [_c('div', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(user.firstname))])]), _c('td', [_c('div', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(user.lastname))])]), _c('td', [_vm._v(_vm._s(user.email))]), _c('td', [_c('small', [_vm._v(_vm._s(user.userRole))])]), _c('td', [_c('small', [_vm._v(_vm._s((user.id || '').substring(0, 5)))])]), _c('td', [_c('small', [_vm._v(_vm._s((user.crmUserId || '').substring(0, 5)))])]), _c('td', [_c('small', [_vm._v(_vm._s((user.crmid || '').substring(0, 5)))])])]);
  }), 0)])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "ChangeUser",
      "name": "ChangeUser",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("User bearbeiten"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ChangeUser');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('user-configuration', {
    attrs: {
      "user": _vm.selectedUser
    },
    on: {
      "saved": function saved($event) {
        return _vm.$modal.hide('ChangeUser');
      },
      "cancelled": function cancelled($event) {
        return _vm.$modal.hide('ChangeUser');
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };