import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('article', [_c('card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "fr"
        }, [_c('small', {
          staticClass: "color-red"
        }, [_vm._v("ROLLE: " + _vm._s(_vm.user.userRole || 'KEINE'))])]), _c('h3', [_vm._v(_vm._s(_vm.user.firstname) + " " + _vm._s(_vm.user.lastname) + " " + _vm._s(_vm.user.akktitle))])];
      },
      proxy: true
    }])
  }, [_c('input-field', {
    attrs: {
      "label": "Vorname:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.firstname,
      expression: "user.firstname"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Vorname"
    },
    domProps: {
      "value": _vm.user.firstname
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "firstname", $event.target.value);
      }
    }
  })]), _c('input-field', {
    attrs: {
      "label": "Nachname:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.lastname,
      expression: "user.lastname"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Nachname"
    },
    domProps: {
      "value": _vm.user.lastname
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "lastname", $event.target.value);
      }
    }
  })]), _c('input-field', {
    attrs: {
      "label": "Email:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.email,
      expression: "user.email"
    }],
    staticClass: "input",
    attrs: {
      "type": "email",
      "placeholder": "email",
      "disabled": ""
    },
    domProps: {
      "value": _vm.user.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "email", $event.target.value);
      }
    }
  })]), _c('input-field', {
    attrs: {
      "label": "Titel:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.akktitle,
      expression: "user.akktitle"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Titel"
    },
    domProps: {
      "value": _vm.user.akktitle
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "akktitle", $event.target.value);
      }
    }
  })]), _c('input-field', {
    attrs: {
      "label": "UID:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.id || _vm.userId,
      expression: "user.id || userId"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "userId",
      "disabled": ""
    },
    domProps: {
      "value": _vm.user.id || _vm.userId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "id || userId", $event.target.value);
      }
    }
  })]), _c('input-field', {
    attrs: {
      "label": "CRM USER ID:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.crmUserId,
      expression: "user.crmUserId"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "crmUserId"
    },
    domProps: {
      "value": _vm.user.crmUserId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "crmUserId", $event.target.value);
      }
    }
  })]), _c('input-field', {
    attrs: {
      "label": "CRM CONTACT ID:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.crmid,
      expression: "user.crmid"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "crmid"
    },
    domProps: {
      "value": _vm.user.crmid
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "crmid", $event.target.value);
      }
    }
  })]), _c('input-field', {
    attrs: {
      "label": "Präf. Nachrichten per:"
    }
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.preferredNotificationTransmission,
      expression: "user.preferredNotificationTransmission"
    }],
    staticClass: "input",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.user, "preferredNotificationTransmission", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "email"
    }
  }, [_vm._v("per Email")]), _c('option', {
    attrs: {
      "value": "webapp"
    }
  }, [_vm._v("per Webapp")])])])], 1), _vm._isGf ? _c('card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h4', [_vm._v("Abrechnung")])];
      },
      proxy: true
    }], null, false, 730137275)
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "value": _vm.user.billingType.type,
      "item": "hourly"
    },
    on: {
      "input": _vm.updateBillingType
    }
  }, [_vm._v("Stündlich")]), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "value": _vm.user.billingType.type,
      "item": "flat"
    },
    on: {
      "input": _vm.updateBillingType
    }
  }, [_vm._v("Pauschal")])], 1), _vm.user.billingType.type === 'hourly' ? _c('input-field', {
    attrs: {
      "label": "Stundenrate"
    }
  }, [_c('money', {
    staticClass: "input",
    model: {
      value: _vm.user.billingType.rate,
      callback: function callback($$v) {
        _vm.$set(_vm.user.billingType, "rate", $$v);
      },
      expression: "user.billingType.rate"
    }
  })], 1) : _vm._e(), _vm.user.billingType.type === 'flat' ? [_c('input-field', {
    attrs: {
      "label": "Pauschalanteil"
    }
  }, [_c('money', {
    staticClass: "input",
    attrs: {
      "suffix": " %",
      "prefix": ""
    },
    model: {
      value: _vm.user.billingType.sharePercentage,
      callback: function callback($$v) {
        _vm.$set(_vm.user.billingType, "sharePercentage", $$v);
      },
      expression: "user.billingType.sharePercentage"
    }
  })], 1), _c('input-field', {
    attrs: {
      "label": "Interne Stundenrate"
    }
  }, [_c('money', {
    staticClass: "input",
    model: {
      value: _vm.user.billingType.virtualRate,
      callback: function callback($$v) {
        _vm.$set(_vm.user.billingType, "virtualRate", $$v);
      },
      expression: "user.billingType.virtualRate"
    }
  })], 1)] : _vm._e()], 2) : _vm._e(), _vm.user && _vm._isAdmin ? _c('card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h4', [_c('span', {
          staticClass: "link",
          on: {
            "click": function click($event) {
              _vm.isShowingRoles = !_vm.isShowingRoles;
            }
          }
        }, [_vm._v("Rollen / Rechte")])])];
      },
      proxy: true
    }], null, false, 3814637067)
  }, [_c('h5', [_vm._v("Rollen")]), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "no",
      "block": ""
    },
    model: {
      value: _vm.user.userRole,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "userRole", $$v);
      },
      expression: "user.userRole"
    }
  }, [_c('span', [_vm._v("KEINE ")]), _c('small', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: 'Keine Rolle: noch keine definiert. Ein Admin muss eine Rolle eingeben'
      },
      expression: "{content: 'Keine Rolle: noch keine definiert. Ein Admin muss eine Rolle eingeben'}"
    }]
  }, [_vm._v("(INFO)")])]), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "sv",
      "block": ""
    },
    model: {
      value: _vm.user.userRole,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "userRole", $$v);
      },
      expression: "user.userRole"
    }
  }, [_c('span', [_vm._v("SV ")]), _c('small', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: 'Sachverständiger: Beim SV müssen zusätzlich Rechte eingegeben werden'
      },
      expression: "{content: 'Sachverständiger: Beim SV müssen zusätzlich Rechte eingegeben werden'}"
    }]
  }, [_vm._v("(INFO)")])]), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "admin",
      "block": ""
    },
    model: {
      value: _vm.user.userRole,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "userRole", $$v);
      },
      expression: "user.userRole"
    }
  }, [_c('span', [_vm._v("ADMIN ")]), _c('small', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: 'ADMIN: kann CRM und Allianz lesen und schreiben. Zusätzlich kann ADMIN User ändern und Rollen und Rechte vergeben'
      },
      expression: "{content: 'ADMIN: kann CRM und Allianz lesen und schreiben. Zusätzlich kann ADMIN User ändern und Rollen und Rechte vergeben'}"
    }]
  }, [_vm._v("(INFO)")])]), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "admin-plus",
      "block": ""
    },
    model: {
      value: _vm.user.userRole,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "userRole", $$v);
      },
      expression: "user.userRole"
    }
  }, [_c('span', [_vm._v("ADMIN+ ")]), _c('small', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: 'ADMIN + kann Honorarnoten einsehen und erstellen'
      },
      expression: "{content: 'ADMIN + kann Honorarnoten einsehen und erstellen'}"
    }]
  }, [_vm._v("(INFO)")])]), _vm._isGf ? _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "gf",
      "block": ""
    },
    model: {
      value: _vm.user.userRole,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "userRole", $$v);
      },
      expression: "user.userRole"
    }
  }, [_c('span', [_vm._v("GF ")]), _c('small', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: 'Geschäftsführer: Alles was ADMIN kann  mit zusätzlichen Rechten beim Eingeben/Ändern von SV-Aufwänden'
      },
      expression: "{content: 'Geschäftsführer: Alles was ADMIN kann  mit zusätzlichen Rechten beim Eingeben/Ändern von SV-Aufwänden'}"
    }]
  }, [_vm._v("(INFO)")])]) : _vm._e(), (_vm.user || {}).rights ? [['admin', 'admin-plus', 'gf'].includes(_vm.user.userRole) ? [_c('h5', [_vm._v("Admin Zusatz Funktions-Rechte")]), _c('v-checkbox', {
    attrs: {
      "label": "CRM: erhält Akte zum Überprüfen (in Entwicklung) ",
      "disabled": "",
      "block": ""
    },
    model: {
      value: _vm.user.rights.crmAdminCorrector,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "crmAdminCorrector", $$v);
      },
      expression: "user.rights.crmAdminCorrector"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "CRM Akt an SV zuteilen (in Entwicklung)",
      "disabled": "",
      "block": ""
    },
    model: {
      value: _vm.user.rights.crmAssignSv,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "crmAssignSv", $$v);
      },
      expression: "user.rights.crmAssignSv"
    }
  }), _vm._isGf || _vm._isAdminPlus ? _c('v-checkbox', {
    attrs: {
      "label": "darf den Ordner 'vertraulich' bearbeiten",
      "block": ""
    },
    model: {
      value: _vm.user.rights.crmMayEditVertraulich,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "crmMayEditVertraulich", $$v);
      },
      expression: "user.rights.crmMayEditVertraulich"
    }
  }) : _vm._e()] : _vm._e(), _vm.user.userRole === 'sv' ? [_c('h5', [_vm._v("SV Rechte - nur für Sachverständige gültig")]), _c('v-checkbox', {
    attrs: {
      "label": "darf neue Features vorzeitig verwenden",
      "block": ""
    },
    model: {
      value: _vm.user.rights.powerUser,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "powerUser", $$v);
      },
      expression: "user.rights.powerUser"
    }
  }), _c('h4', [_vm._v("CRM")]), _c('v-checkbox', {
    attrs: {
      "label": "CRM-Akte muss überprüft werden (in Entwicklung)",
      "disabled": "",
      "block": ""
    },
    model: {
      value: _vm.user.rights.crmNeedsCorrection,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "crmNeedsCorrection", $$v);
      },
      expression: "user.rights.crmNeedsCorrection"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "CRM lesen/schreiben (nur dem Benutzer zugeordnete)",
      "block": ""
    },
    model: {
      value: _vm.user.rights.crmReadWriteOwn,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "crmReadWriteOwn", $$v);
      },
      expression: "user.rights.crmReadWriteOwn"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "CRM lesen/schreiben (alle)",
      "block": ""
    },
    model: {
      value: _vm.user.rights.crmReadWriteAll,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "crmReadWriteAll", $$v);
      },
      expression: "user.rights.crmReadWriteAll"
    }
  }), _vm._isGf ? _c('span', [_c('v-checkbox', {
    attrs: {
      "label": "darf den Odner 'vertraulich' bearbeiten",
      "block": ""
    },
    model: {
      value: _vm.user.rights.crmMayEditVertraulich,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "crmMayEditVertraulich", $$v);
      },
      expression: "user.rights.crmMayEditVertraulich"
    }
  })], 1) : _vm._e(), _c('h4', [_vm._v("Allianz")]), _c('v-checkbox', {
    attrs: {
      "label": "Allianz fertige Berichte lesen (Für Auftraggeber!)",
      "block": ""
    },
    model: {
      value: _vm.user.rights.allianzReadReports,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "allianzReadReports", $$v);
      },
      expression: "user.rights.allianzReadReports"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Allianz lesen/schreiben (nur dem Benutzer zugeordnete) (=SV EXTERN)",
      "block": ""
    },
    model: {
      value: _vm.user.rights.allianzReadWriteOwn,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "allianzReadWriteOwn", $$v);
      },
      expression: "user.rights.allianzReadWriteOwn"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Allianz lesen/schreiben (nicht zugeordente)  (=SV PLUS) ",
      "disabled": !_vm.user.rights.allianzReadWriteOwn,
      "block": ""
    },
    model: {
      value: _vm.user.rights.allianzReadWriteOwnEmpty,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "allianzReadWriteOwnEmpty", $$v);
      },
      expression: "user.rights.allianzReadWriteOwnEmpty"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Allianz lesen/schreiben (alle) (=SV INTERN)",
      "disabled": !_vm.user.rights.allianzReadWriteOwn,
      "block": ""
    },
    model: {
      value: _vm.user.rights.allianzReadWriteAll,
      callback: function callback($$v) {
        _vm.$set(_vm.user.rights, "allianzReadWriteAll", $$v);
      },
      expression: "user.rights.allianzReadWriteAll"
    }
  })] : _vm._e()] : _vm._e()], 2) : _vm._e(), _c('card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h4', [_c('span', {
          staticClass: "link",
          on: {
            "click": function click($event) {
              _vm.isShowingAddress = !_vm.isShowingAddress;
            }
          }
        }, [_vm._v("Adressen")])])];
      },
      proxy: true
    }])
  }, [_vm.user.addresses ? _vm._l(_vm.user.addresses, function (address, idx) {
    return _c('div', [_c('div', {
      staticClass: "ovauto"
    }, [_c('span', {
      staticClass: "bold"
    }, [_vm._v("Adresse: " + _vm._s(address.name))]), _c('div', {
      staticClass: "fr btn _red _small",
      on: {
        "click": function click($event) {
          return _vm.user.addresses.splice(idx, 1);
        }
      }
    }, [_vm._v("diese Adresse entfernen")]), _c('div', {
      staticClass: "fr btn _green _small",
      on: {
        "click": function click($event) {
          return _vm.Prepare_getMapLocationForText(idx);
        }
      }
    }, [_vm._v("Koordinaten bestimmen")])]), _c('div', {
      staticClass: "ovauto"
    }, [_c('div', {
      staticClass: "wz-tags"
    }, [_vm._v(_vm._s(address.loc))])]), _c('div', {
      staticClass: "ovauto"
    }, [_c('input-field', {
      attrs: {
        "label": "Kürzel für Map:"
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: address.name,
        expression: "address.name"
      }],
      staticClass: "input",
      attrs: {
        "type": "text",
        "placeholder": "Kürzel",
        "name": "adress_name"
      },
      domProps: {
        "value": address.name
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(address, "name", $event.target.value);
        }
      }
    })]), _c('input-field', {
      attrs: {
        "label": "Land_PLZ: (A_1010)"
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: address.zip,
        expression: "address.zip"
      }],
      staticClass: "input",
      attrs: {
        "type": "text",
        "placeholder": "PLZ",
        "name": "adress_plz"
      },
      domProps: {
        "value": address.zip
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(address, "zip", $event.target.value);
        }
      }
    })]), _c('input-field', {
      attrs: {
        "label": "Stadt:"
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: address.city,
        expression: "address.city"
      }],
      staticClass: "input",
      attrs: {
        "type": "text",
        "placeholder": "Stadt",
        "name": "adress_sv_city"
      },
      domProps: {
        "value": address.city
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(address, "city", $event.target.value);
        }
      }
    })]), _c('input-field', {
      attrs: {
        "label": "Straße:"
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: address.street,
        expression: "address.street"
      }],
      staticClass: "input",
      attrs: {
        "type": "text",
        "placeholder": "Straße",
        "name": "adress_street"
      },
      domProps: {
        "value": address.street
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(address, "street", $event.target.value);
        }
      }
    })])], 1)]);
  }) : _vm._e(), !_vm.user.addresses ? _c('span', [_c('div', {
    staticClass: "btn _small",
    on: {
      "click": function click($event) {
        return _vm.addAddress();
      }
    }
  }, [_vm._v("Adresse erstellen")])]) : _vm.user.addresses ? _c('span', [_c('div', {
    staticClass: "btn _small",
    on: {
      "click": function click($event) {
        return _vm.user.addresses.push({
          city: ''
        });
      }
    }
  }, [_vm._v("Neue Adresse hinzufügen")])]) : _vm._e()], 2), _vm.user ? _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancelled');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.saveUser();
      }
    }
  }, [_vm._v("Speichern")])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };